<template>
    <Setup-Step
        @next="f => f()"
        @yes="f => addNew()"
        @no="f => f()"
        @restart="stage = 0"
        fluid
        :loadingMsg="loadingMsg"
        :settings="settings"
        :showNext="stage == 0 || isSelfCourier"
        :showRestart="stage != 0 && !isSelfCourier"
        :showYesNo="stage == 0 && !isSelfCourier"
        step="courier-pointers"
        title="Courier Coverage">
        <template v-slot:body="{ next }">
            <v-tabs-items v-model="stage" touchless>
                <!--list-->
                <v-tab-item>
                    <p>In order for BlitzIt Web to automatically select who to get to courier, you need to specify what areas each courier covers.  You can do that by adding courier pointers below.</p>
                    
                    <BT-List-Endless
                        :canSearch="false"
                        :loadingMsg.sync="loadingMsg"
                        maxHeight="60vh"
                        navigation="courier-pointers"
                        :onPullSuccessAsync="pullPointers"
                        :refreshToggle="refreshList">
                        <template v-slot="{ item }">
                            <v-list-item-avatar>
                                <v-icon class="my-auto">mdi-texture-box</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title>Area</v-list-item-title>
                                <v-list-item-subtitle v-if="item.isSelfCourier">Self Courier</v-list-item-subtitle>
                                <v-list-item-subtitle v-else-if="item.courierAgreementID != null">
                                    <BT-Entity
                                        :itemValue="item.courierAgreementID"
                                        itemText="seller.companyName"
                                        navigation="couriers" />
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row no-gutters>
                                    <BT-Btn leftIcon="mdi-pencil" small @click="addNew(item)" />
                                    <BT-Btn leftIcon="mdi-delete" small @click="remove(item)" />
                                </v-row>
                            </v-list-item-action>
                        </template>
                    </BT-List-Endless>
                    <p class="my-4">Would you like to add another courier pointer?</p>
                </v-tab-item>

                <!--self-courier only-->
                <v-tab-item>
                    <p>Please specify the area that you cover for deliveries.  (It could be all of Australia or just a very specific suburb.)</p>
                    
                    <Setup-Info>
                        If you want to use BlitzIt Web to automatically select who to use to courier orders (ie. yourself), then you need to specify the geographical area that you are willing to cover.
                        Any orders with destinations outside of this area will simply require you to manually choose a courier.
                    </Setup-Info>

                    <v-row no-gutters>
                        <v-col cols="auto" class="my-1">
                            <span class="mx-4">
                                <v-icon :color="colorFrom">mdi-square-opacity</v-icon>Pickup Area
                            </span>
                        </v-col>
                        <v-col cols="auto" class="my-1">
                            <span class="mx-4">
                                <v-icon :color="colorTo">mdi-square-opacity</v-icon>Delivery Area
                            </span>
                        </v-col>
                    </v-row>

                    <GmapMap
                        v-if="newItem != null"
                        ref="locMap"
                        :center="center"
                        :zoom="7"
                            style="width: 100%; height: 50vh;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">

                            <gmap-polygon
                                @paths_changed="($event) => { updatePointerPaths($event, newItem, 'boundaryFrom') }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :paths="newItem.boundaryFrom"
                                strokeColor="#006841"
                                :strokeWeight="1" />

                            <gmap-polygon
                                @paths_changed="($event) => { updatePointerPaths($event, newItem, 'boundaryTo') }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :paths="newItem.boundaryTo"
                                strokeColor="#cc0000"
                                :strokeWeight="1" />
                    </GmapMap>

                    <v-slide-x-transition>
                        <BT-Btn
                            v-if="newItem != null && (newItem.id == null || $BlitzIt.tracker.isChanged(newItem))"
                            buttonClass="primary mx-1 mt-4"
                            inline
                            label="Save"
                            @click="save(next)" />
                    </v-slide-x-transition>

                </v-tab-item>

                <!--self or third party-->
                <v-tab-item>
                    <BT-List-Item-Check
                        @change="selfCourier"
                        class="my-3"
                        subtitle="A geographical area for orders that we will deliver ourselves."
                        title="Self Courier" />
                    
                    <BT-List-Item-Check
                        v-if="!isSelectCourier"
                        @true="selectCourier"
                        class="my-3"
                        subtitle="A geographical area for orders that a third party will courier."
                        title="Courier Service" />
                    
                    <BT-Select-List-Box
                        v-else
                        @change="stage += 1"
                        v-model="newItem.courierAgreementID"
                        height="50vh"
                        isEditing
                        isSelecting
                        itemText="seller.companyName"
                        label="Courier Services"
                        navigation="couriers" />
                </v-tab-item>

                <!--boundary-->
                <v-tab-item>
                    <v-row no-gutters>
                        <v-col cols="auto" class="my-1">
                            <span class="mx-4">
                                <v-icon :color="colorFrom">mdi-square-opacity</v-icon>Pickup Area
                            </span>
                        </v-col>
                        <v-col cols="auto" class="my-1">
                            <span class="mx-4">
                                <v-icon :color="colorTo">mdi-square-opacity</v-icon>Delivery Area
                            </span>
                        </v-col>
                    </v-row>
                    <GmapMap
                        ref="locMap"
                        :center="center"
                        :zoom="7"
                            style="width: 100%; height: 60vh;"
                            :options="{
                                zoomControl: true,
                                mapTypeControl: false,
                                scaleControl: false,
                                streetViewControl: false,
                                rotateControl: false,
                                fullscreenControl: false,
                                disableDefaultUi: false }">

                            <gmap-polygon
                                v-for="(pointer, index) in otherPointers"
                                :fillOpacity="0.5"
                                :key="index + 'from'"
                                :paths="pointer.boundaryFrom"
                                :strokeColor="colorFrom"
                                :strokeWeight="1" />

                            <gmap-polygon
                                v-for="(pointer, index) in otherPointers"
                                :fillOpacity="0.5"
                                :key="index + 'to'"
                                :paths="pointer.boundaryTo"
                                :strokeColor="colorTo"
                                :strokeWeight="1" />

                            <gmap-polygon
                                v-if="newItem != null"
                                @paths_changed="($event) => { updatePointerPaths($event, newItem, 'boundaryFrom') }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :paths="newItem.boundaryFrom"
                                :strokeColor="colorFrom"
                                :strokeWeight="1" />

                            <gmap-polygon
                                v-if="newItem != null"
                                @paths_changed="($event) => { updatePointerPaths($event, newItem, 'boundaryTo') }"
                                clickable
                                editable
                                :fillOpacity="0.5"
                                :paths="newItem.boundaryTo"
                                :strokeColor="colorTo"
                                :strokeWeight="1" />
                    </GmapMap>

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Next"
                        @click="stage += 1" />

                </v-tab-item>

                <!--weekday-->
                <v-tab-item v-if="newItem != null">
                    <p>And does this apply always or only certain days of the week?</p>

                    <BT-Select-List
                        :canSearch="false"
                        :items="weekdayOptions()"
                        itemText=""
                        v-model="newItem.weekdays"
                        returnCSV />

                    <BT-Btn
                        buttonClass="primary mx-1 mt-4"
                        inline
                        label="Save"
                        @click="save" />
                    
                </v-tab-item>

            </v-tabs-items>
        </template>
    </Setup-Step>
</template>

<script>
export default {
    name: 'Courier-Pointer-Setup',
    components: {
        BTListItemCheck: () => import('~components/BT-List-Item-Check.vue'),
        BTSelectListBox: () => import('~components/BT-Select-List-Box.vue'),
        SetupInfo: () => import('~home/setup/Setup-Info.vue'),
        SetupStep: () => import('~home/setup/Setup-Step.vue'),
    },
    data() {
        return {
            center: { lat: -38, lng: 144 },
            colorFrom: '#006841',
            colorTo: '#cc0000',
            isSelectCourier: false,
            isSelfCourier: false,
            loadingMsg: null,
            newItem: null,
            otherPointers: [],
            pointers: [],
            refreshList: false,
            stage: 0
        }
    },
    props: {
        settings: { //{ currentIndex: 0, stepperPlan: [], currentStep: 0 }
            type: Object,
            default: null
        },
    },
    methods: {
        addNew(j) {
            this.isSelectCourier = false;

            this.newItem = j || {
                id: null,
                rowVersion: null,
                boundaryFrom: this.getAreaAround(this.center, 0.5),
                boundaryTo: this.getAreaAround(this.center, 1),
                courierAgreementID: null,
                preferStartPickup: false,
                preferEndDropoff: false,
                isSelfCourier: false,
                weekdays: 'Always'
            };

            if (j != null) {
                this.otherPointers = this.pointers.filter(x => x.id != j.id);
                this.stage = 3;
            }
            else {
                this.otherPointers = this.pointers;
                this.stage = 2;
            }
        },
        async pullPointers(list) {
            if (this.settings.data.courieringForSelf &&
                !this.settings.data.courieringForOthers &&
                !this.settings.data.courieringByOthers) {
                if (!this.isLengthyArray(list)) {
                    //add pointer
                    this.newItem = this.$BlitzIt.tracker.track({
                        id: null,
                        rowVersion: null,
                        boundaryFrom: this.getAreaAround(this.center, 0.5),
                        boundaryTo: this.getAreaAround(this.center, 1),
                        courierAgreementID: null,
                        preferStartPickup: false,
                        preferEndDropoff: false,
                        isSelfCourier: true,
                        weekdays: 'Always'
                    }, {
                        trackProps: ['boundaryFrom', 'boundaryTo', 'courierAgreementID', 'preferStartPickup', 'preferEndDropoff', 'isSelfCourier', 'weekdays']
                    });

                    this.stage = 1;
                    this.isSelfCourier = true;
                }
                else if (this.isArrayOfLength(list, 1)) {
                    var p = list[0];
                    if (p.isSelfCourier == true) {
                        this.newItem = this.$BlitzIt.tracker.track(p, {
                            trackProps: ['boundaryFrom', 'boundaryTo', 'courierAgreementID', 'preferStartPickup', 'preferEndDropoff', 'isSelfCourier', 'weekdays']
                        });
                    }
                    this.stage = 1;
                    this.isSelfCourier = true;
                }
                else {
                    this.stage = 0;
                }
            }
            else {
                this.stage = 0;
            }

            this.pointers = list;

            return list;
        },
        async remove(item) {
            try {
                this.loadingMsg = 'Deleting';
                await this.$BlitzIt.store.delete('courier-pointers', item.id);
                this.refreshList = !this.refreshList;
            }
            finally {
                this.loadingMsg = null;
            }
        },
        selfCourier() {
            this.selectCourier = false;
            this.newItem.courierAgreementID = null;
            this.newItem.isSelfCourier = true;
            this.stage += 1;
        },
        selectCourier() {
            this.isSelectCourier = true;
            this.newItem.isSelfCourier = false;
        },
        async save(nextFunc) {
            try {
                this.loadingMsg = 'Saving';

                if (this.newItem != null) {
                    var res = null;

                    if (this.newItem.id == null) {
                        res = await this.$BlitzIt.store.post('courier-pointers', this.newItem);
                    }
                    else {
                        res = await this.$BlitzIt.store.patch('courier-pointers', this.newItem);
                    }

                    this.newItem.id = res.id;
                    this.newItem.rowVersion = res.rowVersion;
                    this.$BlitzIt.tracker.reset(this.newItem);
                }
                
                if (!this.isSelfCourier) {
                    this.stage = 0;
                    this.refreshList = !this.refreshList;
                }
            }
            finally {
                this.loadingMsg = null;

                if (this.isSelfCourier && nextFunc != null) {
                    nextFunc();
                }
            }
            
        },
        updatePointerPaths(mvcArray, zone, prop) {
            let paths = [];
            for (let i=0; i<mvcArray.getLength(); i++) {
              let path = [];
              for (let j=0; j<mvcArray.getAt(i).getLength(); j++) {
                let point = mvcArray.getAt(i).getAt(j);
                path.push({lat: point.lat(), lng: point.lng()});
              }
              paths.push(path);
            }

            zone[prop] = paths[0];

            zone.changed = true;
        },
    }
}
</script>